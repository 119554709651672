// GitHubSection.js
import React from "react";
import { FaGithub } from "react-icons/fa";
import "../styles/GitHubSection.css";

function GitHubSection() {
  return (
    <section className="github-section">
      <div className="container">
        <h2 className="section-title">GitHub Repository</h2>
        <div className="github-button-wrapper">
          <a
            href="https://github.com/your-username/crypto-analyser"
            target="_blank"
            rel="noopener noreferrer"
            className="github-button"
          >
            <FaGithub size={20} className="github-icon" />
            <span className="github-text">Cerasus - Crypto Analyser</span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default GitHubSection;
