import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore"; // Firebase Firestore functions
import { db } from "../firebase"; // Your Firebase config
import "../styles/ReportsSection.css";

function ReportsSection() {
  const [reports, setReports] = useState([]);

  // Fetch reports from Firestore when component loads
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "reports"));
        const reportsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // Spread all fields (title, downloadURL, createdAt, etc.)
        }));
        setReports(reportsData);
      } catch (error) {
        console.error("Error fetching reports: ", error);
      }
    };

    fetchReports();
  }, []);

  return (
    <section className="reports-section">
      <div className="container">
        <h2 className="section-title">Reports</h2>
        <ul className="report-list">
          {reports.map((report) => (
            <li key={report.id}>
              <a
                href={report.downloadURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {report.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default ReportsSection;
