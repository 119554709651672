import React from "react";
import "../styles/ProjectDescription.css";

function ProjectDescription() {
  return (
    <section className="project-description">
      <div className="container">
        <h2 className="section-title">Project Description</h2>
        <p>
          <strong>Cerasus</strong> is a tool for ordinary people who want to
          dive into the cryptocurrency world but need help knowing where to
          start. We aim to provide general news and information tailor-made to
          the user’s needs, making earning from cryptocurrency more prominent
          and easier.
        </p>
        <p>
          We utilize <strong>two complementary machine learning models</strong>.
          One model predicts cryptocurrency price changes based on market data
          and transaction fees. The second model performs{" "}
          <strong>sentiment analysis</strong> on news and tweets from important
          figures to gauge their effect on the market.
        </p>
        <p>
          Users can either manually trade based on our model suggestions or
          enable automatic trading, where the model optimizes their trades.
          <strong> Cerasus</strong> helps users make informed decisions and
          increase profits.
        </p>
      </div>
    </section>
  );
}

export default ProjectDescription;
