import Header from "./components/Header";
import MeetingsSection from "./components/MeetingsSection";
import Footer from "./components/Footer";
import Login from "./components/Login";
import ProjectDescription from "./components/ProjectDescription";
import InstructorSection from "./components/InstructorSection";
import TeamSection from "./components/TeamSection";
import ReportsSection from "./components/ReportsSection";
import GitHubSection from "./components/GitHubSection";
import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Header user={user} />
      <Routes>
        <Route path="/" element={<HomePage user={user} />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

function HomePage({ user }) {
  return (
    <div>
      <ProjectDescription />
      <section id="team">
        <InstructorSection />
        <TeamSection />
      </section>

      <section id="reports">
        <ReportsSection />
        <GitHubSection />
      </section>

      <section id="logs">
        <MeetingsSection user={user} />
      </section>
    </div>
  );
}
