import { FaGithub, FaLinkedin } from "react-icons/fa";
import React from "react";
import goktugImage from "./images/goktug.jpeg";
import idilImage from "./images/idil.jpeg";
import atikaImage from "./images/atika.jpeg";
import denizImage from "./images/deniz.jpeg";
import anilImage from "./images/anil.jpeg";
import "../styles/TeamSection.css";

const teamMembers = [
  {
    name: "İdil Atmaca",
    image: idilImage,
    github: "",
    linkedin: "https://www.linkedin.com/in/idil-atmaca-1a51121bb/",
  },
  {
    name: "Atika Zeynep Evmez",
    image: atikaImage,
    github: "https://github.com/atikazeynep",
    linkedin: "https://www.linkedin.com/in/atikazeynepevmez/",
  },
  {
    name: "Göktuğ Yılmaz",
    image: goktugImage,
    github: "https://github.com/blockerst",
    linkedin: "https://www.linkedin.com/in/goktugyilmazin/",
    position: "bottom",
  },
  {
    name: "Deniz Sun",
    image: denizImage,
    github: "",
    linkedin: "https://www.linkedin.com/in/deniz-sun/",
  },
  {
    name: "Anıl İlağa",
    image: anilImage,
    github: "https://github.com/Birinnnn",
    linkedin: "https://www.linkedin.com/in/anil-ilaga/",
    position: "top",
  },
];

function TeamSection() {
  return (
    <section className="team-section">
      <div className="container">
        <h2 className="section-title">Team Members</h2>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div className="team-member" key={index}>
              <img
                src={member.image}
                alt={member.name}
                className="team-member-image"
                style={{ objectPosition: member.position }}
              />
              <h3>{member.name}</h3>
              <a
                href={member.github}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
              >
                <FaGithub size={30} />
              </a>
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
              >
                <FaLinkedin size={30} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
