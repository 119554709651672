import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAcNtiEuS4k4NHsF55BKD7ocKfinaMcj-g",
  authDomain: "cerasus-d527f.firebaseapp.com",
  projectId: "cerasus-d527f",
  storageBucket: "cerasus-d527f.appspot.com",
  messagingSenderId: "416790305790",
  appId: "1:416790305790:web:2cd07d5bd2bb5978a8beaf",
  measurementId: "G-95HZ5VZYZJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
