import React, { useState } from "react";
import { auth } from "../firebase"; // Firebase auth
import cherryLogo from "../components/images/cherryLogo.png";
import "../styles/Header.css";
import { Link } from "react-scroll";

function Header({ user }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      alert("Logged out successfully!");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <header className="header">
      <div className="container header-content">
        <div className="logo-title">
          <img src={cherryLogo} alt="Cerasus Logo" className="cherry-logo" />
          <div className="title-container">
            <h1 className="project-title">Cerasus</h1>
            <p className="pronunciation">
              Pronunciation: <strong>seh-rah-sus</strong>
            </p>
          </div>
        </div>

        <nav className={`navbar ${isMobileMenuOpen ? "open" : ""}`}>
          <ul className="menu-list">
            <li>
              <Link
                to="team"
                smooth={true}
                duration={500}
                onClick={toggleMobileMenu}
              >
                Our Team
              </Link>
            </li>
            <li>
              <Link
                to="reports"
                smooth={true}
                duration={500}
                onClick={toggleMobileMenu}
              >
                Reports
              </Link>
            </li>
            <li>
              <Link
                to="logs"
                smooth={true}
                duration={500}
                onClick={toggleMobileMenu}
              >
                Logs
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                smooth={true}
                duration={500}
                onClick={toggleMobileMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        {user ? (
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        ) : null}

        <button className="mobile-menu-btn" onClick={toggleMobileMenu}>
          ☰
        </button>
      </div>
    </header>
  );
}

export default Header;
