import React from "react";
import guvenirImage from "../components/images/guvenir.jpg";
import "../styles/InstructorSection.css";

function InstructorSection() {
  return (
    <section className="instructor-section">
      <div className="container">
        <h2 className="section-title">Supervisor</h2>
        <div className="instructor-card-wrapper">
          <div className="instructor-card">
            <img
              src={guvenirImage}
              alt="Halil Altay Güvenir"
              className="instructor-image"
              style={{ objectPosition: "top" }}
            />
            <h3>Prof. Dr. H. Altay Güvenir</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InstructorSection;
